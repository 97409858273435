<template>
  <v-card
    elevation="0"
    style="background: transparent"
  >
    <v-card-text>
      <v-list
        two-line
        subheader
      >
        <v-list-item class="pa-0">
          <v-list-item-avatar
            class="v-avatar-light-bg accent--text justify-center"
            size="40"
            rounded
          >
            <v-icon
              size="30"
              color="accent"
            >
              {{ icons.mdiAccountGroupOutline }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-semibold">
              {{ customerCount }} 人
            </v-list-item-title>
            <v-list-item-subtitle>来店人数</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0">
          <v-list-item-avatar
            class="v-avatar-light-bg accent--text justify-center"
            size="40"
            rounded
          >
            <v-icon
              size="30"
              color="accent"
            >
              {{ icons.mdiGroup }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-semibold">
              {{ customerGroupCount }} 組
            </v-list-item-title>
            <v-list-item-subtitle>来店組数</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>顧客タグ売上</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <circle-chart
          v-if="Object.keys(salesPerCustomerTag).length > 0"
          chart-key="salesPerCustomerTag"
          :chart-data="salesPerCustomerTag"
        />
        <span
          v-else
          class="ms-4"
        >¥0</span>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>キャスト売上</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <circle-chart
          v-if="Object.keys(salesPerCast).length > 0"
          chart-key="salesPerCast"
          :chart-data="salesPerCast"
        />
        <span
          v-else
          class="ms-4"
        >¥0</span>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>アライアンス売上</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <circle-chart
          v-if="Object.keys(salesPerAlliance).length > 0"
          chart-key="salesPerAlliance"
          :chart-data="salesPerAlliance"
        />
        <span
          v-else
          class="ms-4"
        >¥0</span>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccountGroupOutline,
  mdiGroup,
} from '@mdi/js'
import CircleChart from '@/views/components/finance/CircleChart.vue'

export default {
  components: {
    CircleChart,
  },
  props: {
    customerCount: {
      type: Number,
      required: true,
      default: 0,
    },
    customerGroupCount: {
      type: Number,
      required: true,
      default: 0,
    },
    salesPerCustomerTag: {
      type: Object,
      required: true,
      default: () => {},
    },
    salesPerCast: {
      type: Object,
      required: true,
      default: () => {},
    },
    salesPerAlliance: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    return {
      icons: {
        mdiAccountGroupOutline,
        mdiGroup,
      },
    }
  },
}
</script>
