<template>
  <div
    @touchstart="$emit('touchstart')"
    @touchmove="$emit('touchmove')"
    @touchend="$emit('touchend')"
    @touchcancel="$emit('touchcancel')"
  >
    <div ref="chartContainer" />

    <div
      ref="legend"
      class="legend"
    >
      <v-row>
        <v-col
          align-start
          class="pb-0"
        >
          <slot name="legend" />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          align-start
          class="secondary--text py-0 d-flex align-center"
          cols="3"
          md="1"
        >
          <v-divider
            style="max-width: 14px; background: rgba(0, 150, 136)"
            class="mr-1"
          />
          利益
        </v-col>
        <v-col
          align-start
          class="py-0"
          cols="6"
        >
          {{ `¥${targetProfitAmount.toLocaleString()}` }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          align-start
          class="secondary--text py-0 d-flex align-center"
          cols="3"
          md="1"
        >
          <v-divider
            style="max-width: 14px; background: rgb(22, 177, 255);"
            class="mr-1"
          />
          収入
        </v-col>
        <v-col
          align-start
          class="py-0"
          cols="6"
        >
          {{ `¥${targetIncomeAmount.toLocaleString()}` }}
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col
          align-start
          class="secondary--text py-0 d-flex align-center"
          cols="3"
          md="1"
        >
          <v-divider
            style="max-width: 14px; background: rgb(233, 16, 169);"
            class="mr-1"
          />
          支出
        </v-col>
        <v-col
          align-start
          class="py-0"
          cols="6"
        >
          {{ `¥${targetSpendingAmount.toLocaleString()}` }}
        </v-col>
      </v-row>
    </div>

    <slot
      name="extension"
      :income-amount="targetIncomeAmount"
      :spending-amount="targetSpendingAmount"
      :profit-amount="targetProfitAmount"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { last, map } from 'lodash'
import { createChart } from 'lightweight-charts'
import { format, startOfMonth } from 'date-fns'
import { getVuetify } from '@core/utils'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    value: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    incomeList: {
      type: Array,
      required: true,
      default: () => [],
    },
    spendingList: {
      type: Array,
      required: true,
      default: () => [],
    },
    profitList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const targetDate = useVModel(props, 'value')
    const legend = ref(null)
    const targetIncomeAmount = ref(0)
    const targetSpendingAmount = ref(0)
    const targetProfitAmount = ref(0)
    const chartContainer = ref(null)
    const chart = ref(null)
    const theme = {
      light: {
        layout: {
          backgroundColor: '#f4f5fa',
          textColor: '#5e5669',
        },
      },
      dark: {
        layout: {
          backgroundColor: '#28243D',
          textColor: '#e7e3fc',
        },
      },
    }
    const priceFormat = {
      type: 'custom',
      formatter: price => `¥${price.toLocaleString()}`,
    }

    // NOTE: 収入のデータ
    const incomeListAreaSeries = ref(null)
    const incomeListAreaSeriesOptions = {
      topColor: 'rgba(22, 177, 255, 0.56)',
      bottomColor: 'rgba(22, 177, 255, 0.04)',
      lineColor: 'rgba(22, 177, 255, 1)',
      lineWidth: 2,
      priceFormat,
    }

    // NOTE: 支出のデータ
    const spendingListAreaSeries = ref(null)
    const spendingListAreaSeriesOptions = {
      topColor: 'rgba(233, 16, 169, 0.35)',
      bottomColor: 'rgba(233, 16, 169, 0)',
      lineColor: 'rgba(233, 16, 169, 1)',
      lineWidth: 2,
      priceFormat,
    }

    // NOTE: 利益のデータ
    const profitListAreaSeries = ref(null)
    const profitListHistgramSeriesOptions = {
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
      priceFormat,
    }

    const themeKey = computed(() => {
      return $vuetify.theme.isDark ? 'dark' : 'light'
    })

    const incomeListData = computed(() => {
      return map(props.incomeList, o => {
        return { time: o.time, value: +o.value }
      })
    })

    const spendingListData = computed(() => {
      return map(props.spendingList, o => {
        return { time: o.time, value: +o.value }
      })
    })

    const profitListData = computed(() => {
      return map(props.profitList, o => {
        return {
          time: o.time,
          value: Math.abs(+(o.value)),
          color: o.value > 0 ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)',
        }
      })
    })

    const calcChartContainerSize = () => {
      return {
        width: chartContainer.value?.parentElement?.clientWidth || 0,
        height: document.body.clientHeight * 0.7,
      }
    }

    const handleResize = () => {
      chart.value?.applyOptions(calcChartContainerSize())
    }

    // NOTE: チャート上での動き
    const chartCrossHairHandler = param => {
      if (!param.time || param.seriesPrices.size < 3) return

      const targetTime = param.time
      targetDate.value = format(new Date(targetTime.year, (targetTime.month - 1), targetTime.day), 'yyyy-MM-dd')

      const [income, spending, _profit] = param.seriesPrices.values()
      targetIncomeAmount.value = income
      targetSpendingAmount.value = spending
      targetProfitAmount.value = +income - +spending
    }

    const initLegend = () => {
      chart.value.unsubscribeCrosshairMove(chartCrossHairHandler)

      chartContainer.value.prepend(legend.value)

      const now = new Date()
      const targetTime = last(incomeListData.value)?.time || { year: now.getFullYear, month: (now.getMonth() + 2), day: now.getDate() } // NOTE: monthの+2は下の行で-1しているのでそれに合わせるため
      targetDate.value = format(new Date(targetTime.year, (targetTime.month - 1), targetTime.day), 'yyyy-MM-dd')

      targetIncomeAmount.value = last(incomeListData.value).value
      targetSpendingAmount.value = last(spendingListData.value).value
      targetProfitAmount.value = +targetIncomeAmount.value - +targetSpendingAmount.value

      chart.value.subscribeCrosshairMove(chartCrossHairHandler)

      legend.value.style.display = 'block'
    }

    watch(() => themeKey.value, (_newKey, _prevKey) => {
      chart.value?.applyOptions({ ...{ layout: theme[themeKey.value].layout } })
    })

    // NOTE: ライブラリに渡すためのデータ整形（chartに流し込まれた後timeの値はオブジェクトに変わる）
    onMounted(() => {
      // NOTE: チャートのコンテナを作成
      chart.value = createChart(
        chartContainer.value,
        {
          ...calcChartContainerSize(),
          localization: {
            locale: 'ja-JP',
            dateFormat: 'yyyy-MM-dd',
          },
          rightPriceScale: {
            visible: false,
          },
          leftPriceScale: {
            visible: true,
            scaleMargins: {
              top: 0.3,
              bottom: 0.25,
            },
            borderVisible: false,
          },
          layout: {
            fontFamily: '"Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic"',
            ...theme[themeKey.value].layout,
          },
          grid: {
            vertLines: { color: 'rgba(94, 86, 105, 0)' },
            horzLines: { color: 'rgba(94, 86, 105, 0.12)' },
          },
        },
      )

      // NOTE: 収入のチャートを作成
      incomeListAreaSeries.value = chart.value.addAreaSeries(incomeListAreaSeriesOptions)
      incomeListAreaSeries.value.setData(incomeListData.value)

      // NOTE: 支出のチャートを作成
      spendingListAreaSeries.value = chart.value.addAreaSeries(spendingListAreaSeriesOptions)
      spendingListAreaSeries.value.setData(spendingListData.value)

      // NOTE: 利益のチャート(Volume)を作成
      profitListAreaSeries.value = chart.value.addHistogramSeries(profitListHistgramSeriesOptions)
      profitListAreaSeries.value.setData(profitListData.value)

      // NOTE: 凡例の初期化
      initLegend()

      // NOTE: 時間軸の再調整
      chart.value.timeScale().setVisibleRange({
        from: startOfMonth(new Date()).getTime() / 1000,
        to: new Date().getTime() / 1000,
      })
      chart.value.timeScale().scrollToPosition(5)
    })

    window.addEventListener('resize', handleResize)
    // eslint-disable-next-line no-restricted-globals
    onUnmounted(() => window.removeEventListener('resize', handleResize))

    return {
      // data
      chartContainer,
      legend,
      targetDate,
      targetIncomeAmount,
      targetSpendingAmount,
      targetProfitAmount,

      // computed
      incomeListData,
      spendingListData,
      profitListData,
    }
  },
}
</script>

<style lang="scss" scoped>
.legend {
  display: none;
}
</style>
