<template>
  <div>
    <v-treeview
      :items="items"
    >
      <template #label="{ item: node }">
        <v-row>
          <v-col
            cols="6"
            class="white-space-normal"
          >
            {{ node.name }}
          </v-col>
          <v-col
            cols="6"
            align-end
            class="white-space-normal"
          >
            {{ `¥${node.amount.toLocaleString()}` }}
          </v-col>
        </v-row>
      </template>

      <template #append="{ item: node }">
        <v-btn
          v-if="!readOnly && node.name === '諸費'"
          :ripple="false"
          icon
          color="primary"
          @click="getExpenseTags(); initExpenseValues()"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>

        <div
          v-if="!readOnly && node.meta && node.meta.ids && node.meta.ids.length"
          class="d-flex align-center justify-content-end"
        >
          <v-btn
            icon
            :ripple="false"
            small
            @click="getExpenseTags(); initExpenseValues(JSON.parse(node.meta.ids))"
          >
            <v-icon small>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>

          <yona-edit-dialog
            v-if="!readOnly && node.meta && node.meta.ids && node.meta.ids.length"
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(JSON.parse(node.meta.ids))"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </div>
      </template>
    </v-treeview>

    <v-dialog
      v-if="!readOnly"
      :value="isFormOpen"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ isUpdate ? '諸費 編集' : '新規 諸費' }}
        </v-card-title>

        <v-skeleton-loader
          v-if="isLoading"
          type="article, actions"
        />

        <v-card-text v-else>
          <v-card
            v-for="expense in expenseValues"
            :key="`expense-${expense.id}`"
            class="mt-4 mb-8"
          >
            <v-row class="px-4">
              <v-col cols="6">
                <v-combobox
                  v-model="expense.expensePrimaryLabelId"
                  :items="primaryTags"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  :return-object="false"
                  :error="!expense.expensePrimaryLabelId"
                  clearable
                  chips
                  hide-details
                  small-chips
                  label="大ラベル"
                  :menu-props="{ auto: true, offsetY: true }"
                >
                  <template #selection="{ item }">
                    <v-chip small>
                      <span v-if="primaryTags.find(tag => +tag.id === +item)">
                        {{ primaryTags.find(tag => +tag.id === +item).name }}
                      </span>
                      <span v-else>
                        {{ item }}
                      </span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <v-combobox
                  v-model="expense.expenseSecondaryLabelId"
                  :items="secondaryTags"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  :return-object="false"
                  clearable
                  chips
                  small-chips
                  hide-details
                  label="小ラベル"
                  :menu-props="{ auto: true, offsetY: true }"
                >
                  <template #selection="{ item }">
                    <v-chip small>
                      <span v-if="secondaryTags.find(tag => +tag.id === +item)">
                        {{ secondaryTags.find(tag => +tag.id === +item).name }}
                      </span>
                      <span v-else>
                        {{ item }}
                      </span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model.number="expense.amount"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="1"
                  prefix="¥"
                  label="金額"
                  :error="expense.amount < 0"
                />
              </v-col>

              <v-checkbox
                v-model="expense.withCashRegisterTransaction"
                label="現金"
                :ripple="false"
                class="mt-0 ms-4"
              />
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :ripple="false"
            :loading="isSubmitting"
            :disabled="!(isValid.expensePrimaryLabelId && isValid.amount)"
            @click="submit"
          >
            {{ isUpdate ? '更新' : '追加' }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :ripple="false"
            @click="resetExpenseValues"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import {
  chain,
  groupBy,
  map,
  sumBy,
  flatMap,
  isEmpty,
  every,
} from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import ExpenseTagApi from '@/api/waiter/ExpenseTag'
import ExpenseApi from '@/api/admin/Expense'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  props: {
    laborCosts: { // NOTE: 給料
      type: Array,
      required: true,
      default: () => [],
    },
    expenses: { // NOTE: 諸費
      type: Array,
      required: true,
      default: () => [],
    },
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    expenseOn: {
      type: String,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { hhmm } = useDateFormat()
    const { currentClub } = useCurrentData()
    const primaryTags = ref([])
    const secondaryTags = ref([])
    const isFormOpen = ref(false)
    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const expenseValues = ref([])
    const getClub = inject('getClub', () => {})

    const items = computed(() => {
      return [
        {
          id: '給料',
          name: '給料',
          amount: sumBy(props.laborCosts, laborCost => +laborCost.amount),
          children: chain(props.laborCosts)
            .map(laborCost => {
              return {
                id: `laborCost-${laborCost.role}`,
                name: {
                  cast: 'キャスト',
                  waiter: 'ボーイ',
                  alliance: 'アライアンス',
                }[laborCost.role],
                amount: +laborCost.amount,
              }
            })
            .value(),
        },
        {
          id: '諸費',
          name: '諸費',
          amount: sumBy(props.expenses, expense => +expense.attributes.amount),
          children: chain(props.expenses)
            .groupBy('meta.expensePrimaryLabelName')
            .map((expenses, expensePrimaryLabelName) => {
              return {
                id: expensePrimaryLabelName,
                name: expensePrimaryLabelName,
                amount: sumBy(expenses, expense => +expense.attributes.amount),
                children: chain(expenses)
                  .groupBy('meta.expenseSecondaryLabelName')
                  .map((subExpenses, expenseSecondaryLabelName) => {
                    return {
                      id: `${expensePrimaryLabelName}-${expenseSecondaryLabelName}`,
                      name: expenseSecondaryLabelName === 'null' ? '-' : expenseSecondaryLabelName,
                      amount: sumBy(subExpenses, subExpense => +subExpense.attributes.amount),
                      meta: {
                        ids: JSON.stringify(map(subExpenses, 'id')),
                      },
                    }
                  })
                  .value(),
              }
            })
            .value(),
        },
      ]
    })

    const isValid = computed(() => {
      const expensePrimaryLabelIds = chain(expenseValues.value).map('expensePrimaryLabelId').compact().value()
      const amounts = chain(expenseValues.value).map('amount').compact().value()

      return {
        expensePrimaryLabelId: expensePrimaryLabelIds.length === expenseValues.value.length,
        amount: every(amounts, amount => +amount >= 0),
      }
    })

    const isUpdate = computed(() => {
      return every(expenseValues.value, expense => !!expense.id)
    })

    const resetExpenseValues = () => {
      expenseValues.value = []
      isFormOpen.value = false
    }

    const initExpenseValues = (ids = []) => {
      resetExpenseValues()
      isFormOpen.value = true

      if (isEmpty(ids)) {
        expenseValues.value = [{
          amount: 0,
          expenseOn: props.expenseOn || new Date(`${props.date.replace(/-/g, '/')} ${hhmm(currentClub.value.openAt)}`),
          expensePrimaryLabelId: null,
          expenseSecondaryLabelId: null,
          withCashRegisterTransaction: false,
        }]

        return
      }

      const targetIds = map(ids, Number)

      expenseValues.value = chain(props.expenses)
        .filter(expense => {
          return targetIds.includes(+expense.id)
        })
        .map(expense => {
          const {
            amount,
            expenseOn,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
          } = expense.attributes

          return {
            id: expense.id,
            amount,
            expenseOn,
            expensePrimaryLabelId,
            expenseSecondaryLabelId,
            withCashRegisterTransaction: expense.meta.withCashRegisterTransaction,
          }
        })
        .value()
    }

    const submit = async () => {
      isSubmitting.value = true

      const verb = isUpdate.value ? 'update' : 'create'

      const res = await ExpenseApi[`${verb}Expenses`](expenseValues.value)

      if (res?.status === 200) {
        emit('updated')
        vm.$toast.success(`諸費を${isUpdate.value ? '更新' : '追加'}しました`)
        getClub() // NOTE: 時点レジ金を更新するため
      }

      isSubmitting.value = false
      resetExpenseValues()
    }

    const destroy = async ids => {
      const res = await ExpenseApi.deleteExpenses(ids)

      if (res) {
        emit('updated')
        vm.$toast.success('諸費を削除しました')
      }
    }

    const getExpenseTags = async () => {
      isLoading.value = true

      const res = await ExpenseTagApi.getExpenseTags()

      if (res?.data) {
        const { tags } = res.data.expenseTags.data.attributes
        primaryTags.value = tags.primaryTags
        secondaryTags.value = tags.secondaryTags
      }

      isLoading.value = false
    }

    return {
      // data
      isFormOpen,
      isLoading,
      isSubmitting,
      primaryTags,
      secondaryTags,
      expenseValues,

      // computed
      items,
      isValid,
      isUpdate,

      // methods
      getExpenseTags,
      initExpenseValues,
      resetExpenseValues,
      submit,
      destroy,

      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.white-space-normal {
  white-space: normal;
}
</style>
