<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="image, image, image, image"
      min-height="100%"
      min-width="100%"
    />
  </div>

  <div v-else>
    <v-btn-toggle
      v-model="targetInterval"
      mandatory
      dense
      class="elevation-2 mb-4"
      @change="getFinances"
    >
      <v-btn
        v-for="interval in ['日', '週', '月', '年']"
        :key="interval"
        depressed
        :ripple="false"
      >
        {{ interval }}
      </v-btn>
    </v-btn-toggle>

    <chart
      :key="`${JSON.stringify([...incomeList, ...spendingList, ...profitList])}`"
      v-model="targetDate"
      :income-list="incomeList"
      :spending-list="spendingList"
      :profit-list="profitList"
      @touchstart="isTouchingChart = true"
      @touchmove="isTouchingChart = true"
      @touchend="isTouchingChart = false"
      @touchcancel="isTouchingChart = false"
      @update:value="targetDate = $event"
    >
      <template #legend>
        <clip-loader
          v-if="isLoadingDrawerContent"
          :loading="true"
          :color="'#8A8D93'"
          size="16px"
          style="text-align: left !important;"
        />

        <span v-else>{{ legendDate }}</span>
      </template>

      <template #extension="{ incomeAmount, spendingAmount, profitAmount }">
        <div class="chart-drawer">
          <v-navigation-drawer
            app
            right
            stateless
            permanent
            temporary
            hide-overlay
            clipped
            :mini-variant="miniVariant"
            width="400"
          >
            <v-card
              tile
              elevation="0"
              height="100%"
              style="background: transparent"
            >
              <div class="d-flex align-center">
                <v-btn
                  tile
                  fab
                  :ripple="false"
                  color="primary"
                  @click="miniVariant = !miniVariant"
                >
                  <v-icon>{{ icons.mdiViewAgendaOutline }}</v-icon>
                </v-btn>

                <v-tabs
                  v-model="currentTab"
                  right
                  @change="getDrawerContents"
                >
                  <v-tab
                    v-for="tab in tabs"
                    :key="`chart-drawer-tabs[${tab.key}]`"
                  >
                    {{ tab.text }}
                  </v-tab>
                </v-tabs>
              </div>

              <clip-loader
                v-if="isLoadingDrawerContent"
                :loading="true"
                :color="'#8A8D93'"
                size="20px"
                class="my-4"
              />

              <v-tabs-items
                v-else
                v-show="!miniVariant"
                v-model="currentTab"
                touchless
                style="background: transparent"
              >
                <v-card
                  elevation="0"
                  style="background: transparent"
                  class="pb-0"
                >
                  <v-card-text class="text-base pb-0">
                    <v-icon left>
                      {{ icons.mdiCalendar }}
                    </v-icon>
                    {{ legendDate }}

                    <v-divider class="w-hull mt-1 mb-4" />
                  </v-card-text>
                </v-card>

                <v-tab-item>
                  <detail-tab-item
                    :date="targetDate"
                    :profit-amount="profitAmount"
                    :income-amount="incomeAmount"
                    :spending-amount="spendingAmount"
                    :sales-amount="salesDetail.salesAmount"
                    :cash-amount="salesDetail.cashAmount"
                    :card-amount="salesDetail.cardAmount"
                    :balance-receivable-amount="salesDetail.balanceReceivableAmount"
                    :benefits="benefits"
                    :expenses="expenses"
                    :labor-costs="laborCosts"
                  />
                </v-tab-item>

                <v-tab-item>
                  <performance-tab-item
                    :date="targetDate"
                    :customer-count="performance.customerCount"
                    :customer-group-count="performance.customerGroupCount"
                    :sales-per-customer-tag="performance.salesPerCustomerTag"
                    :sales-per-cast="performance.salesPerCast"
                    :sales-per-alliance="performance.salesPerAlliance"
                  />
                </v-tab-item>

                <v-card
                  elevation="0"
                  style="background: transparent"
                  class="pb-6"
                >
                  <v-card-text class="pb-0">
                    <router-link
                      :to="{
                        name: 'waiter-daily-reports',
                        query: {
                          date: targetDate,
                          interval: targetInterval,
                        },
                      }"
                    >
                      <span class="text-decoration-underline cursor-pointer link--text">
                        日報を確認
                      </span>
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-tabs-items>
            </v-card>
          </v-navigation-drawer>
        </div>
      </template>
    </chart>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  computed,
} from '@vue/composition-api'
import { last, find } from 'lodash'
import { format } from 'date-fns'
import { mdiViewAgendaOutline, mdiCalendar } from '@mdi/js'
import camelcaseKeys from 'camelcase-keys'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import useCompInit from '@/views/composable/useCompInit'
import FinanceApi from '@/api/admin/Finance'
import SalesApi from '@/api/admin/Sales'
import BenefitApi from '@/api/admin/Benefit'
import ExpenseApi from '@/api/admin/Expense'
import LaborCostApi from '@/api/admin/LaborCost'
import Chart from '@/views/components/finance/Chart.vue'
import DetailTabItem from '@/views/components/finance/DetailTabItem.vue'
import PerformanceTabItem from '@/views/components/finance/PerformanceTabItem.vue'

export default {
  components: {
    Chart,
    ClipLoader,
    DetailTabItem,
    PerformanceTabItem,
  },
  setup() {
    const { isLoading, initWith } = useCompInit()
    const targetInterval = ref(0) // NOTE: 0 => 日, 1 => 週, 2 => 月, 3 => 年
    const targetDate = ref('') // NOTE: yyyy-MM-dd

    // NOTE: chart
    const isTouchingChart = ref(false)
    const incomeList = ref([])
    const spendingList = ref([])
    const profitList = ref([])

    // NOTE: drawer
    const tabs = [
      { key: 'detail', text: '概要' },
      { key: 'performance', text: 'パフォーマンス' },
    ]
    const miniVariant = ref(false)
    const isLoadingDrawerContent = ref(true)
    const currentTab = ref(0)
    const salesDetail = reactive({
      salesAmount: 0,
      cashAmount: 0,
      cardAmount: 0,
      balanceReceivableAmount: 0,
    })
    const benefits = ref([])
    const expenses = ref([])
    const laborCosts = ref([])
    const performance = reactive({
      customerCount: 0,
      customerGroupCount: 0,
      salesPerCast: {},
      salesPerCustomerTag: {},
      salesPerAlliance: {},
    })

    const legendDate = computed(() => {
      if (targetInterval.value === 0) return format(new Date(targetDate.value.replace(/-/g, '/')), 'yyyy/MM/dd')

      const formatStr = 'yyyy/MM/dd HH:mm'
      const data = find(profitList.value, o => o.time === targetDate.value)
      const {
        startTime,
        endTime,
      } = data
      const formattedStartTime = format(new Date(startTime), formatStr)
      const formattedEndTime = format(new Date(endTime), formatStr)

      return `${formattedStartTime} ~ ${formattedEndTime}`
    })

    const getFinances = async () => {
      const res = await FinanceApi.getFinances({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        incomeList.value = camelcaseKeys(res.data.incomeList)
        spendingList.value = camelcaseKeys(res.data.spendingList)
        profitList.value = camelcaseKeys(res.data.profitList)

        targetDate.value = last(incomeList.value).time
      }
    }

    const getSalesDetail = async () => {
      const res = await SalesApi.getDetail({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        salesDetail.salesAmount = res.data.detail.salesAmount
        salesDetail.cashAmount = res.data.detail.cashAmount
        salesDetail.cardAmount = res.data.detail.cardAmount
        salesDetail.balanceReceivableAmount = res.data.detail.balanceReceivableAmount
      }
    }

    const getBenefits = async () => {
      const res = await BenefitApi.getBenefits({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        benefits.value = res.data.benefits.data
      }
    }

    const getExpenses = async () => {
      const res = await ExpenseApi.getExpenses({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        expenses.value = res.data.expenses.data
      }
    }

    const getLaborCosts = async () => {
      const res = await LaborCostApi.getLaborCosts({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        laborCosts.value = res.data.laborCosts
      }
    }

    const getSalesPerformance = async () => {
      const res = await SalesApi.getPerformance({
        date: targetDate.value,
        interval: targetInterval.value,
      })

      if (res?.status === 200) {
        performance.customerCount = res.data.performance.customerCount
        performance.customerGroupCount = res.data.performance.customerGroupCount
        performance.salesPerCustomerTag = res.data.performance.salesPerCustomerTag
        performance.salesPerCast = res.data.performance.salesPerCast
        performance.salesPerAlliance = res.data.performance.salesPerAlliance
      }
    }

    const getDetail = () => {
      initWith(
        [
          getSalesDetail(),
          getBenefits(),
          getExpenses(),
          getLaborCosts(),
        ],
        isLoadingDrawerContent,
      )
    }

    const getPerformance = () => {
      initWith(
        [
          getSalesPerformance(),
        ],
        isLoadingDrawerContent,
      )
    }

    const getDrawerContents = () => {
      const currentKey = tabs[currentTab.value].key

      switch (currentKey) {
        case 'detail':
          getDetail()
          break

        case 'performance':
          getPerformance()
          break

        default:
          break
      }
    }

    const debounce = (cb, timeout = 800) => {
      let timer

      return (...args) => {
        clearTimeout(timer)

        timer = setTimeout(() => cb.apply(this, args), timeout)
      }
    }

    watch(
      () => [targetDate.value, targetInterval.value],
      () => {
        isLoadingDrawerContent.value = true

        debounce(() => getDrawerContents())()
      },
    )

    initWith([
      getFinances(),
    ])

    return {
      // data
      isLoading,
      isLoadingDrawerContent,
      isTouchingChart,
      miniVariant,
      incomeList,
      spendingList,
      profitList,
      targetInterval,
      targetDate,
      tabs,
      currentTab,
      salesDetail,
      benefits,
      expenses,
      laborCosts,
      performance,

      // computed
      legendDate,

      // methods
      getFinances,
      getDrawerContents,

      icons: {
        mdiViewAgendaOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/preset/preset/mixins.scss';

  @include theme--child(chart-drawer) using ($material) {
    .v-navigation-drawer
    {
      background: rgba(map-deep-get($material, 'background'), 0.7);
      backdrop-filter: blur(6px);
    }
  }

  .ps-drawer-tab-item{
    max-height: calc(var(--vh, 1vh) * 97);
  }

  .navbar-content-container {
    padding-right: 48px;
  }
</style>
