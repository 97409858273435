<template>
  <div>
    <v-card
      elevation="0"
      style="background: transparent"
    >
      <v-card-text>
        <v-list
          two-line
          subheader
        >
          <v-list-item class="pa-0">
            <v-list-item-avatar
              class="v-avatar-light-bg success--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="success"
              >
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ `¥${profitAmount.toLocaleString()}` }}
              </v-list-item-title>
              <v-list-item-subtitle>利益</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0">
            <v-list-item-avatar
              class="v-avatar-light-bg primary--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiCurrencyJpy }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ `¥${incomeAmount.toLocaleString()}` }}
              </v-list-item-title>
              <v-list-item-subtitle>収入</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <income-treeview
            :sales-amount="salesAmount"
            :cash-amount="cashAmount"
            :card-amount="cardAmount"
            :balance-receivable-amount="balanceReceivableAmount"
            :benefits="benefits"
            :date="date"
            read-only
          />

          <v-list-item class="pa-0">
            <v-list-item-avatar
              color="primary"
              class="v-avatar-light-bg secondary--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="secondary"
              >
                {{ icons.mdiChartBar }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ `¥${spendingAmount.toLocaleString()}` }}
              </v-list-item-title>
              <v-list-item-subtitle>支出</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <spending-treeview
            :labor-costs="laborCosts"
            :expenses="expenses"
            :date="date"
            read-only
          />
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiCurrencyJpy,
  mdiChartBar,
} from '@mdi/js'
import IncomeTreeview from '@/views/components/daily_report/BusinessResult/IncomeTreeview.vue'
import SpendingTreeview from '@/views/components/daily_report/BusinessResult/SpendingTreeview.vue'

export default {
  components: {
    IncomeTreeview,
    SpendingTreeview,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    profitAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    incomeAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    spendingAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    salesAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cashAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cardAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    balanceReceivableAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    benefits: {
      type: Array,
      required: true,
      default: () => [],
    },
    laborCosts: {
      type: Array,
      required: true,
      default: () => [],
    },
    expenses: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiTrendingUp,
        mdiCurrencyJpy,
        mdiChartBar,
      },
    }
  },
}
</script>
