<template>
  <div>
    <v-treeview
      :items="items"
    >
      <template #label="{ item: node }">
        <v-row>
          <v-col
            cols="6"
            class="white-space-normal"
          >
            {{ node.name }}
          </v-col>
          <v-col
            cols="6"
            align-end
            class="white-space-normal"
          >
            {{ `¥${node.amount.toLocaleString()}` }}
          </v-col>
        </v-row>
      </template>

      <template #append="{ item: node }">
        <v-btn
          v-if="!readOnly && node.name === '諸入'"
          icon
          color="primary"
          :ripple="false"
          @click="getBenefitTags(); initBenefitValues()"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>

        <div
          v-if="!readOnly && node.meta && node.meta.ids && node.meta.ids.length"
          class="d-flex align-center justify-content-end"
        >
          <v-btn
            icon
            small
            :ripple="false"
            @click="getBenefitTags(); initBenefitValues(JSON.parse(node.meta.ids))"
          >
            <v-icon small>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>

          <yona-edit-dialog
            v-if="!readOnly && node.meta && node.meta.ids && node.meta.ids.length"
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(JSON.parse(node.meta.ids))"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </div>
      </template>
    </v-treeview>

    <v-dialog
      v-if="!readOnly"
      :value="isFormOpen"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ isUpdate ? '諸入 編集' : '新規 諸入' }}
        </v-card-title>

        <v-skeleton-loader
          v-if="isLoading"
          type="article, actions"
        />

        <v-card-text v-else>
          <v-card
            v-for="benefit in benefitValues"
            :key="`benefit-${benefit.id}`"
            class="mt-4 mb-8"
          >
            <v-row class="px-4">
              <v-col cols="6">
                <v-combobox
                  v-model="benefit.benefitPrimaryLabelId"
                  :items="primaryTags"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  :return-object="false"
                  :error="!benefit.benefitPrimaryLabelId"
                  clearable
                  chips
                  hide-details
                  small-chips
                  label="大ラベル"
                  :menu-props="{ auto: true, offsetY: true }"
                >
                  <template #selection="{ item }">
                    <v-chip small>
                      <span v-if="primaryTags.find(tag => +tag.id === +item)">
                        {{ primaryTags.find(tag => +tag.id === +item).name }}
                      </span>
                      <span v-else>
                        {{ item }}
                      </span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <v-combobox
                  v-model="benefit.benefitSecondaryLabelId"
                  :items="secondaryTags"
                  :item-value="item => item.id"
                  :item-text="item => item.name"
                  :return-object="false"
                  clearable
                  chips
                  small-chips
                  hide-details
                  label="小ラベル"
                  :menu-props="{ auto: true, offsetY: true }"
                >
                  <template #selection="{ item }">
                    <v-chip small>
                      <span v-if="secondaryTags.find(tag => +tag.id === +item)">
                        {{ secondaryTags.find(tag => +tag.id === +item).name }}
                      </span>
                      <span v-else>
                        {{ item }}
                      </span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model.number="benefit.amount"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="1"
                  prefix="¥"
                  label="金額"
                  :error="benefit.amount < 0"
                />
              </v-col>

              <v-checkbox
                v-model="benefit.withCashRegisterTransaction"
                label="現金"
                :ripple="false"
                class="mt-0 ms-4"
              />
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :ripple="false"
            :loading="isSubmitting"
            :disabled="!(isValid.benefitPrimaryLabelId && isValid.amount)"
            @click="submit"
          >
            {{ isUpdate ? '更新' : '追加' }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :ripple="false"
            @click="resetBenefitValues"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import {
  chain,
  groupBy,
  map,
  sumBy,
  flatMap,
  isEmpty,
  every,
} from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import BenefitTagApi from '@/api/waiter/BenefitTag'
import BenefitApi from '@/api/admin/Benefit'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  props: {
    salesAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cashAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    cardAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    balanceReceivableAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    benefits: { // NOTE: 諸入
      type: Array,
      required: true,
      default: () => [],
    },
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    benefitOn: {
      type: String,
      default: () => new Date().toISOString('ja-JP'),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { hhmm } = useDateFormat()
    const { currentClub } = useCurrentData()
    const primaryTags = ref([])
    const secondaryTags = ref([])
    const isFormOpen = ref(false)
    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const benefitValues = ref([])
    const getClub = inject('getClub', () => {})

    const items = computed(() => {
      return [
        {
          id: '売上',
          name: '売上',
          amount: props.salesAmount,
          children: [
            {
              id: '現金',
              name: '現金',
              amount: props.cashAmount,
            },
            {
              id: 'カード',
              name: 'カード',
              amount: props.cardAmount,
            },
            {
              id: '売掛',
              name: '売掛',
              amount: props.balanceReceivableAmount,
            },
          ],
        },
        {
          id: '諸入',
          name: '諸入',
          amount: sumBy(props.benefits, benefit => +benefit.attributes.amount),
          children: chain(props.benefits)
            .groupBy('meta.benefitPrimaryLabelName')
            .map((benefits, benefitPrimaryLabelName) => {
              return {
                id: benefitPrimaryLabelName,
                name: benefitPrimaryLabelName,
                amount: sumBy(benefits, benefit => +benefit.attributes.amount),
                children: chain(benefits)
                  .groupBy('meta.benefitSecondaryLabelName')
                  .flatMap((subBenefits, benefitSecondaryLabelName) => {
                    return {
                      id: `${benefitPrimaryLabelName}-${benefitSecondaryLabelName}`,
                      name: benefitSecondaryLabelName === 'null' ? '-' : benefitSecondaryLabelName,
                      amount: sumBy(subBenefits, subBenefit => +subBenefit.attributes.amount),
                      meta: {
                        ids: JSON.stringify(map(subBenefits, 'id')),
                      },
                    }
                  })
                  .value(),
              }
            })
            .value(),
        },
      ]
    })

    const isValid = computed(() => {
      const benefitPrimaryLabelIds = chain(benefitValues.value).map('benefitPrimaryLabelId').compact().value()
      const amounts = chain(benefitValues.value).map('amount').compact().value()

      return {
        benefitPrimaryLabelId: benefitPrimaryLabelIds.length === benefitValues.value.length,
        amount: every(amounts, amount => +amount >= 0),
      }
    })

    const isUpdate = computed(() => {
      return every(benefitValues.value, benefit => !!benefit.id)
    })

    const resetBenefitValues = () => {
      benefitValues.value = []
      isFormOpen.value = false
    }

    const initBenefitValues = (ids = []) => {
      resetBenefitValues()
      isFormOpen.value = true

      if (isEmpty(ids)) {
        benefitValues.value = [{
          amount: 0,
          benefitOn: props.benefitOn || new Date(`${props.date.replace(/-/g, '/')} ${hhmm(currentClub.value.openAt)}`),
          benefitPrimaryLabelId: null,
          benefitSecondaryLabelId: null,
          withCashRegisterTransaction: false,
        }]

        return
      }

      const targetIds = map(ids, Number)

      benefitValues.value = chain(props.benefits)
        .filter(benefit => {
          return targetIds.includes(+benefit.id)
        })
        .map(benefit => {
          const {
            amount,
            benefitOn,
            benefitPrimaryLabelId,
            benefitSecondaryLabelId,
          } = benefit.attributes

          return {
            id: benefit.id,
            amount,
            benefitOn,
            benefitPrimaryLabelId,
            benefitSecondaryLabelId,
            withCashRegisterTransaction: benefit.meta.withCashRegisterTransaction,
          }
        })
        .value()
    }

    const submit = async () => {
      isSubmitting.value = true

      const verb = isUpdate.value ? 'update' : 'create'

      const res = await BenefitApi[`${verb}Benefits`](benefitValues.value)

      if (res?.status === 200) {
        emit('updated')
        vm.$toast.success(`諸入を${isUpdate.value ? '更新' : '追加'}しました`)
        getClub() // NOTE: 時点レジ金を更新するため
      }

      isSubmitting.value = false
      resetBenefitValues()
    }

    const destroy = async ids => {
      const res = await BenefitApi.deleteBenefits(ids)

      if (res) {
        emit('updated')
        vm.$toast.success('諸入を削除しました')
      }
    }

    const getBenefitTags = async () => {
      isLoading.value = true

      const res = await BenefitTagApi.getBenefitTags()

      if (res?.data) {
        const { tags } = res.data.benefitTags.data.attributes
        primaryTags.value = tags.primaryTags
        secondaryTags.value = tags.secondaryTags
      }

      isLoading.value = false
    }

    return {
      // data
      isFormOpen,
      isLoading,
      isSubmitting,
      primaryTags,
      secondaryTags,
      benefitValues,

      // computed
      items,
      isValid,
      isUpdate,

      // methods
      getBenefitTags,
      initBenefitValues,
      resetBenefitValues,
      submit,
      destroy,

      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.white-space-normal {
  white-space: normal;
}
</style>
