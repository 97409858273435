<template>
  <div>
    <vue-apex-charts
      v-if="chartDataAmount && chartDataAmount.length > 0"
      :id="chartKey"
      :options="chartOptions"
      :series="chartDataAmount"
      type="donut"
      class="h-full d-flex align-center"
    />

    <v-container v-if="chartDataAmount && chartDataAmount.length > 0">
      <v-row>
        <v-col
          cols="6"
          align-start
        >
          <div class="pa-2">
            凡例
          </div>
        </v-col>
        <v-col
          cols="1"
          offset="4"
          align-end
        >
          <v-btn
            icon
            :ripple="false"
            @click="isShowChartLegends = !isShowChartLegends"
          >
            <v-icon>
              {{ isShowChartLegends ? icons.mdiChevronUp : icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="w-full" />

      <!-- legend -->
      <v-row
        v-show="isShowChartLegends"
        class="mt-1 py-1"
      >
        <v-col
          v-for="(labelName, index) in chartDataLabels"
          :key="index"
          cols="6"
        >
          <span class="d-flex align-center">
            <v-avatar
              size="20"
              :color="chartOptionsColors[index]"
              class="mr-1"
            />
            {{ labelName }}
          </span>

          <p class="ml-4 mt-1">
            ¥{{ chartDataAmount[index].toLocaleString() }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { chain } from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import hexCode from '@/plugins/colorList'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    // 例 { 'customer_a': 12000, 'customer_b': 15000 }
    chartData: {
      type: Object,
      required: true,
    },
    chartKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isShowChartLegends = ref(false)
    const yonaFavorableColorCodes = chain(hexCode)
      .flatMap(o => { return o.color.match(/blue|violet|dark/) ? o.code.hex : [] })
      .shuffle()
      .value()

    const chartDataLabels = computed(() => {
      if (props.chartData === 'undefined') {
        return []
      }

      return Object.keys(props.chartData)
    })

    const chartDataAmount = computed(() => {
      if (props.chartData === 'undefined') {
        return []
      }

      return Object.values(props.chartData)
    })

    const generateRandomColorCodeArray = numbersArrayLength => {
      if (yonaFavorableColorCodes.length >= numbersArrayLength) {
        return yonaFavorableColorCodes.slice(0, numbersArrayLength)
      }

      return hexCode.slice(0, numbersArrayLength).map(c => c.code.hex)
    }

    const dataLength = computed(() => Object.values(props.chartData).length)

    const chartOptionsColors = computed(() => generateRandomColorCodeArray(dataLength.value))

    const chartOptions = ref({
      labels: chartDataLabels.value,
      colors: chartOptionsColors.value,
      chart: {
        type: 'pie',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '1rem',
                fontFamily: '"Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic"',
                offsetY: 25,
              },
              value: {
                fontSize: '1.125rem',
                fontFamily: '"Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic"',
                fontWeight: 600,
                offsetY: -15,
                formatter(value) {
                  return `¥${Number(value).toLocaleString()}`
                },
              },
              total: {
                show: true,
                label: '',
                fontSize: '1.125rem',
                fontFamily: '"Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic"',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `¥${value.globals.seriesTotals.reduce((total, num) => total + num).toLocaleString()}`
                },
              },
            },
          },
        },
      },
    })

    watch(
      () => chartDataLabels,
      () => {
        chartOptions.value = {
          ...chartOptions.value,
          labels: [...chartDataLabels.value],
          colors: [...chartOptionsColors.value],
        }
      },
      { deep: true },
    )

    return {
      isShowChartLegends,
      chartOptions,
      chartOptionsColors,
      chartDataLabels,
      chartDataAmount,

      icons: {
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
